.rmsc {
  --rmsc-main: #4285f4 !important;
  --rmsc-hover: #f1f3f5 !important;
  --rmsc-selected: #e2e6ea !important;
  --rmsc-border: #ccc !important;
  --rmsc-gray: #aaa !important;
  --rmsc-bg: #fff !important;
  --rmsc-p: 10px !important;
  --rmsc-radius: 4px !important;
  --rmsc-h: auto !important;
}

.dropdown-heading {
  min-height: 32px;
}

.dropdown-heading-value span {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  padding: 10px 0px;
}

.search input {
  height: 40px !important;
}

.rmsc .options {
  scrollbar-width: thin;
  scrollbar-color: rgb(156, 156, 156) #f2f2f2;
}
.rmsc .options::-webkit-scrollbar, html .rmsc .options::-webkit-scrollbar {
  height: 12px;
  width: 7px;
}

.rmsc .options::-webkit-scrollbar-track, html .rmsc .options::-webkit-scrollbar-track {
  background: #f2f2f2;
}

.rmsc .options::-webkit-scrollbar-thumb, html .rmsc .options::-webkit-scrollbar-thumb {
  background-color: rgb(156, 156, 156);
  border-radius: 5px;
  border: 3px solid rgb(156, 156, 156);
}