$bgColor: white;
$shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
$borderRadius: .4rem;
$border-color: #ccc;
$border: 1px solid $border-color;

.dropbtn {
  border-radius: $borderRadius;
  cursor: pointer;  
  border: $border;
  background-color: white;
  z-index: 101;
  position: relative;
  top: 1px;

  &.noOutline {
    border-color: transparent;
  }
  
  &.medium {
    padding: 0.5rem 0.8rem;
  }

  &.small {
    padding: 0;
  }
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;

  // &:hover .dropdownContent {
  //   display: block;
  //   box-shadow: $shadow;
  //   border-radius: 0 $borderRadius $borderRadius $borderRadius;

  //   &.left {
  //     right: 0px;
  //     border-radius: $borderRadius 0 $borderRadius $borderRadius;
  //   }
  // }

  & .dropdownContent.shown {
    display: block;
    box-shadow: $shadow;
    border-radius: 0 $borderRadius $borderRadius $borderRadius;

    &.left {
      right: 0px;
      border-radius: $borderRadius 0 $borderRadius $borderRadius;
    }
  }


  & .dropbtn.shown {
    background-color: $bgColor;
    border-radius: $borderRadius $borderRadius 0 0;
    border-color: $border-color $border-color transparent $border-color;
  }
}

/* Dropdown Content (Hidden by Default) */
.dropdownContent {
  display: none;
  position: absolute;
  background-color: $bgColor;
  // min-width: 160px;
  z-index: 100; 
  border: $border;

  &.medium {
    padding: 1rem 0.8rem;
  }

  &.small {
    padding: .5rem;
  }

  & a {
    color: black;
    padding: 6px 8px;
    margin: 4px;
    text-decoration: none;
    display: block;
    font-size: 1rem;

    &:hover {
      background-color: #f1f1f1;
    }
  }
}
