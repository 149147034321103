@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: 'Inter', sans-serif, system-ui;
  }
}

.grecaptcha-badge {
  opacity: 0 !important;
}

/* Scrollbar */
.stylledScroll {
  scrollbar-width: thin;
  scrollbar-color: rgb(156, 156, 156) #f2f2f2;
}
.stylledScroll::-webkit-scrollbar, html .stylledScroll::-webkit-scrollbar {
  height: 12px;
  width: 7px;
}

.stylledScroll::-webkit-scrollbar-track, html .stylledScroll::-webkit-scrollbar-track {
  background: #f2f2f2;
}

.stylledScroll::-webkit-scrollbar-thumb, html .stylledScroll::-webkit-scrollbar-thumb {
  background-color: rgb(156, 156, 156);
  border-radius: 5px;
  border: 3px solid rgb(156, 156, 156);
}